import { slackConfig } from '../credentials';
import { ChatProviderIdEnum } from '../provider.enum';
import { ChannelTypeEnum } from '../../../types';
export const chatProviders = [
    {
        id: ChatProviderIdEnum.Slack,
        displayName: 'Slack',
        channel: ChannelTypeEnum.CHAT,
        credentials: slackConfig,
        docReference: 'https://api.slack.com/docs',
        logoFileName: { light: 'slack.svg', dark: 'slack.svg' },
    },
    {
        id: ChatProviderIdEnum.Discord,
        displayName: 'Discord',
        channel: ChannelTypeEnum.CHAT,
        credentials: [],
        docReference: 'https://discord.com/developers/docs/intro',
        logoFileName: { light: 'discord.svg', dark: 'discord.svg' },
    },
    {
        id: ChatProviderIdEnum.MsTeams,
        displayName: 'MSTeams',
        channel: ChannelTypeEnum.CHAT,
        credentials: [],
        docReference: 'https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook',
        logoFileName: { light: 'msteams.svg', dark: 'msteams.svg' },
    },
];
