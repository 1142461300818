var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
export class HttpClient {
    constructor(backendUrl) {
        this.backendUrl = backendUrl;
        this.axiosClient = axios.create({
            baseURL: backendUrl + '/v1',
        });
    }
    setAuthorizationToken(token) {
        this.axiosClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
    disposeAuthorizationToken() {
        delete this.axiosClient.defaults.headers.common.Authorization;
    }
    getFullResponse(url, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.axiosClient
                .get(url, {
                params,
            })
                .then((response) => response.data);
        });
    }
    get(url, params) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.axiosClient
                .get(url, {
                params,
            })
                .then((response) => response.data.data);
        });
    }
    post(url, body = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.axiosClient.post(url, body).then((response) => response.data.data);
        });
    }
    patch(url, body = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.axiosClient.patch(url, body).then((response) => response.data.data);
        });
    }
}
